<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="mb-0">Category</h4>
      </div>
      <div class="card-body billpayment-menu">
        <router-link
          v-for="category in categoryList"
          :key="category._id"
          class="d-flex justify-content-between text-decoration-none text-dark"
          :to="{
            name: category.name,
            params: {
              category: category.name,
              id: billMarketId,
            },
          }"
        >
          <h6 class="card-title">{{ category ? category.name : "" }}</h6>
          <b-icon-chevron-right></b-icon-chevron-right>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "category",
  data() {
    return {
      billMarketId: null,
    };
  },
  computed: {
    ...mapState({
      categoryList: (state) => state.billPayment.categoryList,
      marketList: (state) => state["home"].marketList,
    }),
  },
  methods: {
    setBillMarketId() {
      const selectBillMarket = this.marketList.find(
        (e) => e.name === "Bill Payment"
      );
      this.billMarketId = selectBillMarket._id;
    },
  },
  async mounted() {
    await this.setBillMarketId();
    await this.$store.dispatch("billPayment/getCategory", this.billMarketId);
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>
<style scoped>
.text-orange {
  color: #ff6a2e;
}

.billpayment-menu .router-link-exact-active {
  color: #ff6a2e !important;
}
</style>
